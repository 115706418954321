var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{class:'modal-allowed-capacity',attrs:{"title":_vm.isEditing ? 'Edit Release Note' : 'New Release Note',"show":_vm.show,"submitLabel":"Save","loadingSubmit":_vm.loadingSubmit,"isCancel":true,"isSubmit":true},on:{"hide":_vm.hide,"onSubmit":_vm.onSubmit},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14px-normal form-timeoff-type",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Date","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Enter Date","icon-right":"calendar-blank-outline","trap-focus":"","editable":""},model:{value:(_vm.formData.releaseDate),callback:function ($$v) {_vm.$set(_vm.formData, "releaseDate", $$v)},expression:"formData.releaseDate"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Description","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"type":"textarea","placeholder":"Enter Description"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"name":"start date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Start Date","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Enter Date","icon-right":"calendar-blank-outline","trap-focus":"","editable":""},model:{value:(_vm.formData.startDate),callback:function ($$v) {_vm.$set(_vm.formData, "startDate", $$v)},expression:"formData.startDate"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"name":"end date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"End Date","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Enter Date","icon-right":"calendar-blank-outline","trap-focus":"","editable":""},model:{value:(_vm.formData.endDate),callback:function ($$v) {_vm.$set(_vm.formData, "endDate", $$v)},expression:"formData.endDate"}})],1)]}}],null,true)})],1)])])]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }