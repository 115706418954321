<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Release Note">
      <template #page-header>
        <h2 class="page-title">Release Note</h2>
      </template>
      <template #action-bar>
        <div class="level">
          <div class="level-left">
            <b-button
              class="is-command has-red-text"
              icon-left="plus"
              @click="openModalCreate()"
            >
              New Release Note
            </b-button>
          </div>
        </div>
      </template>
      <template #page-content>
        <div
          id="release-note-table"
          class="table-timeoff-type table-request-personal"
        >
          <b-table
            :data="releaseNoteList"
            :per-page="perPage"
            :loading="isReleaseNoteListLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            @sort="onSort"
            :sticky-header="stickyHeaders"
            class="employment-directory-table table-log-activity-personal general-table"
          >
            <template>
              <b-table-column
                field="id"
                label="No"
                width="3%"
                numeric
                v-slot="props"
              >
                {{ props.index + 1 }}
              </b-table-column>
              <b-table-column
                field="release_date"
                label="Release Date"
                width="15%"
                sortable
                v-slot="props"
              >
                <!-- TODO: Add Date Formatter -->
                {{ formatDate(props.row.releaseDate, 'DD/MM/YYYY') }}
              </b-table-column>
              <b-table-column
                field="date_from"
                label="Date Range"
                width="25%"
                sortable
                v-slot="props"
              >
                <!-- TODO: Add Date Formatter -->
                {{
                  formatDate(props.row.dateFrom, 'DD/MM/YYYY') +
                  ' - ' +
                  formatDate(props.row.dateTo, 'DD/MM/YYYY')
                }}
              </b-table-column>
              <b-table-column
                field="description"
                label="Description"
                width="50%"
                v-slot="props"
                class="is-capitalize"
              >
                {{ props.row.description }}
              </b-table-column>
              <b-table-column
                field="action"
                width="14%"
                label="Action"
                v-slot="props"
              >
                <b-icon
                  type="is-primary"
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="timeoff-type-delete-icon"
                  @click.native="openModalCreate(props.row)"
                ></b-icon>
                <b-icon
                  type="is-primary"
                  icon="delete"
                  custom-size="mdi-18px"
                  class="timeoff-type-delete-icon"
                  @click.native="deletionWarning(props.row.id)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <ReleaseNoteModal
          :show="isModalOpen"
          :loadingSubmit="isSubmitting"
          :formData="data"
          @hide="closeModalCreate"
          @submit="submit"
        />
      </template>
    </r-page>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast, formatDate } from '@/services/util'
import moment from 'moment-timezone'

// component
import ReleaseNoteModal from './ReleaseNote/ReleaseNoteModal.vue'

export default {
  data() {
    return {
      stickyHeaders: false,
      isPaginated: false,
      perPage: 10,
      page: 0,
      lastPage: 0,
      total: 0,
      defaultSortOrder: 'desc',
      sortField: 'created_at',
      sortOrder: 'desc',
      search: '',
      locale: 'en-GB',
      isReleaseNoteListLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,
      data: {
        id: null,
        releaseDate: null,
        startDate: null,
        endDate: null,
        description: null,
      },
      isSubmitting: null,
    }
  },
  async mounted() {
    this.setReleaseNoteList([])
    await this.loadReleaseNoteList()
  },
  computed: {
    ...mapGetters({
      releaseNoteList: 'releaseNoteModule/getReleaseNotesList',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadReleaseNoteList: 'releaseNoteModule/fetchReleaseNotesList',
      saveReleaseNote: 'releaseNoteModule/saveReleaseNote',
      editReleaseNote: 'releaseNoteModule/editReleaseNote',
      deleteReleaseNote: 'releaseNoteModule/deleteReleaseNote',
    }),
    ...mapMutations({
      setReleaseNoteList: 'releaseNoteModule/setReleaseNoteList',
    }),

    /**
     * Load release note data list
     */
    async loadReleaseNoteList() {
      this.isReleaseNoteListLoading = true
      try {
        const response = await this.actionLoadReleaseNoteList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
        })
        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (e) {
        console.log(e)
      }
      this.isReleaseNoteListLoading = false
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadReleaseNoteList()
    },

    /**
     * Reset state of table
     */
    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setReleaseNoteList([])
      await this.loadReleaseNoteList()
    },

    /**
     * Load more table data
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadReleaseNoteList()
        this.isLoadMore = false
      }
    },

    /**
     * Open create modal. If row present,
     * set form to include data from selected table row
     * @param {integer} row - table row index (optional)
     */
    openModalCreate(row) {
      if (row) {
        this.isEditing = true
        this.data = {
          id: row.id,
          releaseDate: new Date(row.releaseDate),
          startDate: new Date(row.dateFrom),
          endDate: new Date(row.dateTo),
          description: row.description,
        }
      }
      this.isModalOpen = true
    },

    /**
     * Close create modal
     */
    closeModalCreate() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    /**
     * Reset form when modal is closed
     */
    resetForm() {
      this.data = {
        id: null,
        releaseDate: null,
        startDate: null,
        endDate: null,
        description: null,
      }
    },

    /**
     * Set Format Date
     * @param {dateString} str - date string
     * @return {dateString} formatted date
     */
    formatDate(date, format) {
      return formatDate(date, format)
    },

    /**
     * submit release note data
     */
    async submit() {
      this.isSubmitting = true
      let form = new FormData()
      form.append(
        'release_date',
        moment(this.data.releaseDate).format('YYYY-MM-DD')
      )
      form.append('date_from', moment(this.data.startDate).format('YYYY-MM-DD'))
      form.append('date_to', moment(this.data.endDate).format('YYYY-MM-DD'))
      form.append('description', this.data.description)
      try {
        if (!this.isEditing) {
          await this.saveReleaseNote(form)
          showToast('Data is successfully added!', 'is-success', 'is-top')
        } else {
          form.append('_method', 'PUT')
          let data = { id: this.data.id, form }
          await this.editReleaseNote(data)
          showToast('Data is successfully edited!', 'is-success', 'is-top')
        }
      } catch (e) {
        showToast(e.message, 'is-danger', 'is-top')
      }
      this.isSubmitting = false
      this.isEditing = false
      this.resetForm()
      this.isModalOpen = false
    },

    /**
     * Open warning sweet alert when deleting a data
     * @param {integer} id - selected ID from database
     */
    deletionWarning(id) {
      this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        denyButtonText: 'No',
        inputValue: 1,
        customClass: {
          confirmButton: 'button is-primary',
          denyButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: 'Are you sure you want to delete this data?',
      }).then(async (res) => {
        try {
          if (res && res.isConfirmed) {
            await this.deleteReleaseNote(id)
            this.$swal({
              icon: 'success',
              titleText: 'Data is successfully deleted!',
              confirmButtonText: 'Done',
              customClass: {
                confirmButton: 'button is-success',
              },
            })
          }
        } catch (e) {
          this.$swal({
            icon: 'error',
            titleText: 'Failed. Please try again!',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'button is-grey',
            },
          })
        }
      })
    },
  },
  components: { ReleaseNoteModal },
}
</script>
